<template>
  <div>
    <h1 class="text-h4">Управление продажами</h1>
    <v-divider class="my-2"></v-divider>
    <v-form ref="form" v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Рекомендации по {{ order.code }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-img
                v-if="image!=null"
                :src="image | imageUrl('medium', false)"
                class="rounded my-1"
              ></v-img>
            </v-col>
            <v-col>
              <v-textarea
                v-model="form.data.notes"
                label="Комментарии к изображению"
                counter="2048"
              ></v-textarea>
              <v-switch
                v-model="form.data.isPublic"
                label="Видно заказчику"
                dense
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :to="{name: 'order.view', params: { id }}">Отмена</v-btn>
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapState } from 'vuex'
import OrderUpdateForm from '@/model/order/OrderUpdateForm'
import ToastService from '@/service/ToastService'
import { ruleNumeric, ruleRequired } from '@/utils/FormRules'
import DropDownDatePicker from '@/components/ui/DropDownDatePicker.vue'
import { orderStatusList } from '@/utils/OrderStatus'
import OrderManageForm from '@/model/order/OrderManageForm'
import DropDownTimePicker from '@/components/ui/DropDownTimePicker.vue'

export default {
  ...dashboardPageCommonAttributes,
  name: 'OrderImageEditPage',
  props: {
    id: {
      type: [String],
      required: true
    },
    imageId: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      order: {
        code: null
      },
      form: {
        valid: true,
        data: {
          notes: null,
          isPublic: false
        },
        rule: {
          notes: []
        }
      },
      orderImage: null
    }
  },
  computed: {
    ...mapState('order_image_edit', ['item', 'busy']),
    image() {
      return this.orderImage?.image
    }
  },
  created() {
    this.fetchData({ id: this.id })
      .then((_) => {
        this.order.code = this.item.code

        this.orderImage = this.item.images.find((i) => i.id === this.imageId)

        this.form.data.notes = this.orderImage.notes
        this.form.data.isPublic = this.orderImage.isPublic

        // set form
        // this.form.data.shippingDate = this.item.shippingAt.substring(0,10)
        // this.form.data.shippingTime = this.item.shippingAt.substring(11)
      })
  },
  methods: {
    ...mapActions('order_image_edit', ['fetchData', 'updateItem']),
    submitForm() {
      const { data } = this.form

      this.updateItem({ id: this.imageId, notes:data.notes, isPublic:data.isPublic }).then(() => {
        ToastService.success('Данные сохранены')

        this.$router.go(-1)
      })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    }
  }
}
</script>
