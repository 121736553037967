export default class OrderUpdateForm {
  constructor(data) {
    this.orderDate = data?.orderDate
    this.status = data?.status
    this.customerName = data?.customerName
    this.customerPhone = data?.customerPhone
    this.customerNotes = data?.customerNotes
    this.deliveryType = data?.deliveryType
    this.deliveryShopId = data?.deliveryShopId
    this.deliveryDate = data?.deliveryDate
    this.deliveryTimeSlot = data?.deliveryTimeSlot
    this.recipientName = data?.recipientName
    this.recipientPhone = data?.recipientPhone
    this.deliveryAddressCity = data?.deliveryAddressCity
    this.deliveryAddressStreet = data?.deliveryAddressStreet
    this.deliveryAddressStreetNumber = data?.deliveryAddressStreetNumber
    this.deliveryAddressApartment = data?.deliveryAddressApartment
    this.deliveryNotes = data?.deliveryNotes
    this.deliveryPrice = data?.deliveryPrice
  }

  static create(data) {
    return new OrderUpdateForm(data)
  }

}
